<template>
	<RecordViewWrapper>
		<sdPageHeader title="">
			<template #buttons
				><div class="search-box">
					<span class="search-icon">
						<sdFeatherIcons type="search" size="14" />
					</span>
					<input
						v-model="search"
						type="text"
						name="recored-search"
						placeholder="Search reviews by product name"
					/></div
			></template>
		</sdPageHeader>
		<Main>
			<a-row :gutter="15">
				<a-col class="w-100" :md="24">
					<sdCards headless>
						<div v-if="store.state.actions.isLoading" class="spin">
							<a-spin />
						</div>

						<div v-else>
							<TableWrapper class="table-data-view table-responsive">
								<a-table
									:rowSelection="rowSelection"
									:pagination="{ pageSize: 10, showSizeChanger: true }"
									:dataSource="filterSearch"
									:columns="columns"
								/>
							</TableWrapper>
						</div>
					</sdCards>
				</a-col>
			</a-row>
		</Main>
	</RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from "@/components/table/style";
import ProductService from "@/services/ProductService";
import store from "@/vuex/store";
export default {
	name: "ReviewsList",

	components: {
		RecordViewWrapper
	},

	data() {
		return {
			store: store,
			columns: [
				{
					title: "Review",
					dataIndex: "review",
					key: "review"
				},
				{
					title: "Rating",
					dataIndex: "rating",
					key: "rating"
				},
				{
					title: "Product",
					dataIndex: "product_url",
					key: "product_url"
				}
			],
			dataSource: [],
			search: ""
		};
	},

	computed: {
		filterSearch() {
			return this.dataSource.filter(review => {
				return review.product_name.toLowerCase().includes(this.search);
			});
		}
	},

	mounted() {
		this.getReviews();
	},

	methods: {
		async getReviews() {
			this.$store.dispatch("actions/setLoading", true);

			const reviews = (await ProductService.getReviews()).data.reviews;
			this.dataSource = reviews.map((review, key) => {
				return {
					key: key + 1,
					product_url: <a href={review.product_url}>{review.product_name}</a>,
					review: review.review,
					rating: review.rating,
					product_name: review.product_name
				};
			});

			this.$store.dispatch("actions/setLoading", false);
		}
	}
};
</script>