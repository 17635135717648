<template>
	<div>
		<sdPageHeader title="Reviews"></sdPageHeader>
		<Main>
			<a-row :gutter="25">
				<a-col :xs="24">
					<sdCards headless>
						<sdCards>
							<ReviewsList />
						</sdCards>
					</sdCards>
				</a-col>
			</a-row>
		</Main>
	</div>
</template>

<script>
import { Main } from "../styled";
import ReviewsList from "./ReviewsList.vue";

export default {
	name: "Reviews",
	components: {
		Main,
		ReviewsList
	}
};
</script>
